var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('YoTable', {
    attrs: {
      "api-endpoint": _vm.apiEndpoint,
      "title-tbl": _vm.titleTbl,
      "sub-title-tbl": this.$t('Land Vehicles'),
      "fields": _vm.fields.filter(function (field) {
        return field.gone === true;
      }),
      "new-data-template": _vm.newDataTemplate,
      "resolve-fetch-response": _vm.resolveFetchResponse,
      "resolve-fetch-url": _vm.resolveFetchUrl,
      "bottom-label": this.$t('KeteranganTable') + '~' + this.$t('Land Vehicles') + ' [CO] ~',
      "bottom-icon": "TruckIcon",
      "info-page-ability": "Manage (All / Agen All).",
      "placeholder-search": "Nama Kendaraan"
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }
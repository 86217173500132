<template>
  <YoTable
    :api-endpoint="apiEndpoint"
    :title-tbl="titleTbl"
    :sub-title-tbl="this.$t('Land Vehicles')"
    :fields="fields.filter((field) => field.gone === true)"
    :new-data-template="newDataTemplate"
    :resolve-fetch-response="resolveFetchResponse"
    :resolve-fetch-url="resolveFetchUrl"
    :bottom-label="
      this.$t('KeteranganTable') + '~' + this.$t('Land Vehicles') + ' [CO] ~'
    "
    bottom-icon="TruckIcon"
    info-page-ability="Manage (All / Agen All)."
    placeholder-search="Nama Kendaraan"
  />
</template>

<script>
import moment from 'moment'
import YoTable from '@/views/pages/components/CompTableT1T2.vue'

export default {
  components: {
    YoTable
  },
  data() {
    return {
      apiEndpoint: 'dm_land_vehicle',
      titleTbl: 'DATA MASTER',
      fields: [
        {
          minWidth: '130px',
          key: 'group.name',
          label: 'GROUP',
          placeholder: '',
          sortable: true,
          input: { type: 'input-noarea', disabled: true },
          searchModel: 'group.name',
          search: {
            type: 'text'
          },
          gone: true
        },
        {
          key: 'plat_no_pol',
          label: 'Plat Nomor POLISI',
          placeholder: 'Cth: KT 1503 DR',
          sortable: true,
          input: { type: 'input-noarea' },
          searchModel: 'plat_no_pol',
          search: {
            type: 'text'
          },
          gone: true
        },
        {
          key: 'capacity',
          label: 'Capacity ( LTR )',
          placeholder: 'Hanya Angka',
          sortable: true,
          input: { type: 'number-append', append: 'Ltr' },
          searchModel: 'capacity',
          search: {
            type: 'number'
          },
          acl: this.$can('manage', 'all') || !this.$can('manage', 'form3'),
          gone: this.$can('manage', 'all') || !this.$can('manage', 'form3')
        },
        {
          key: 'type_vehicle',
          label: 'Jenis Kendaraan',
          sortable: true,
          input: {
            type: 'select',
            options: [
              {
                label: 'TRUCK',
                value: 'TRUCK'
              },
              {
                label: 'LONGBED',
                value: 'LONGBED'
              },
              {
                label: 'MOBIL BOX',
                value: 'MOBIL BOX'
              },
              {
                label: 'MOBIL',
                value: 'MOBIL'
              },
              {
                label: 'BIS',
                value: 'BIS'
              }
            ],
            reduce({ value }) {
              return value
            }
          },
          searchModel: 'type_vehicle',
          search: {
            type: 'text'
          },
          acl: this.$can('manage', 'form3'),
          gone: this.$can('manage', 'form3')
        },
        {
          key: 'user.full_name',
          label: 'Created BY',
          sortable: true,
          placeholder: 'Otomatis terisi',
          input: { type: 'input-noarea', disabled: true },
          searchModel: 'user.full_name',
          search: {
            type: 'text'
          },
          gone: true
        },
        {
          searchModel: 'user.phone',
          search: {
            type: 'number'
          },
          key: 'user.phone',
          label: 'Phone of Created BY',
          sortable: true,
          placeholder: 'Otomatis terisi',
          input: { type: 'input-noarea', disabled: true },
          gone: true
        },
        {
          key: 'created_at',
          label: 'Created AT',
          sortable: true,
          placeholder: 'Otomatis terisi',
          input: {
            type: 'date',
            format: 'D MMMM YYYY - hh:mm A',
            disabled: true
          },
          searchModel: 'created_at',
          search: {
            type: 'date'
          },
          gone: true
        },
        {
          key: 'updated_at',
          label: 'Updated AT',
          sortable: true,
          placeholder: 'Otomatis terisi',
          input: {
            type: 'date',
            format: 'D MMMM YYYY - hh:mm A',
            disabled: true
          },
          searchModel: 'updated_at',
          search: {
            type: 'date'
          },
          gone: true
        }
      ]
    }
  },
  computed: {
    newDataTemplate() {
      return {
        plat_no_pol: '',
        capacity: '',
        type_vehicle: 'TRUCK'
      }
    }
  },
  methods: {
    moment,
    resolveFetchResponse(response) {
      return response.data.dm_land_vehicles
    },
    resolveFetchUrl({ pagination }) {
      return {
        url: this.apiEndpoint,
        query: {
          page: pagination.currentPage - 1,
          size: pagination.selectedAmount,
          sort: 'DESC'
        }
      }
    }
  }
}
</script>
